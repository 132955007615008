
body { 
  background: url(/img/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  font-family: "Lora", serif;
  font-size: 1em;
  color: #fff9f5;
}



h1, h2, h3, h4, .card-title, button, .modal-title {
  font-family: "Lora", serif; }

h1 {
  font-size: 8em; }

h2 {
  font-size: 5em; }

.rightBorder {
  border-right: 1px solid;
  border-color: gray;
}


.navbar {
  background-color: rgba(0, 0, 0, 1);
  
   }

.navbar-nav {
  margin-left: 20%;
  margin-right: 0%;
  width: 100%;
  text-align: center; }

.navbar-nav li {
  min-width: 20%; }

.underline {
  position: relative; }

.underline:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 1px;
  bottom: 5px;
  left: 5%;
  background-color: gray;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.underline:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.site-logo {
  width: 30px;
  height: 30px;
  display: block;
  overflow: visible;
  border: 2px solid #fff9f5;
  position: relative;
  transform: rotate(0deg);
  transition: transform .4s ease-in-out;
  flex-shrink: 0; 
  }

.site-logo .logo-text {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: transform .4s ease-in-out;
  font-weight: bold;
  font-size: .6em; }

.site-logo:hover {
  opacity: 1;
  transform: rotate(-90deg);
  transition: transform .4s ease-in-out; }

.site-logo:hover .logo-text {
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center; }

.site-logo:hover #logo-stroke {
  transform: rotate(-90deg);
  transform-origin: center center;
  transition: transform 0.5s ease-in-out; }

.intro-block {
  padding-bottom: 0%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)); 
  color: #fff9f5; }
  .intro-block .main-heading {
    padding-top: 10%;
    padding-bottom: 10%;
    line-height: .9em;
      }
  .intro-block .sub-heading {
    padding-right: 40%;
    font-size: 1.1em; }
  
#scroll {
  opacity: 0.5;
  width: 15%;
  animation-name: scroll;
  animation-delay: 3s;
  animation-duration: 2.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 3;
  transition: transform 1s; }

#scroll a {
  color: #fff9f5; 
}
  
@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: .5; }
  50% {
    transform: translateY(20px);
    opacity: .5; }
  100% {
    transform: translateY(0px);
    opacity: .5; } }

#scroll:hover {
  animation-play-state: paused;
  cursor: pointer;
  position: relative;
  transform: translateY(20px); }

a:hover {
  text-decoration: none;
}


.first-block {
  background-color: #fff9f5;
  color: #3D405B;
  padding: 10% 20% 7%; }
  .first-block h1 {
    line-height: .9em;
    padding: 15% 5%; }
  .first-block p {
    padding: 0% 5%; }

a.skills {
    background-color: "#3D405B";
  }
  

.first-block-scroll {
  background-color: #fff9f5;
  color: #3D405B; }
  .first-block-scroll #scroll-about {
    color: #3D405B;
    width: 15%;
    transition: transform 1s; }
  .first-block-scroll #scroll-about:hover {
    animation-play-state: paused;
    cursor: pointer;
    position: relative;
    transform: translateY(20px); }
  .first-block-scroll #scroll-about a {
    color: #3D405B; }

.second-block {
  background-color: #fff9f5;
  color: #3D405B;
  padding-top: 5%; }
.second-block #Projects {
  padding-top: 10%;
  padding-bottom: 5%; }
.second-block .project-box {
  position: relative;
  color: #fff9f5;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  transition: transform 1s; 
  margin-top: 12px;
  margin-bottom: 12px; 
  min-height: 90%; }
.second-block .project-box:hover {
  animation-play-state: paused;
  cursor: pointer;
  position: relative;
  transform: translateY(10px); }
.project0 {
  background-color: #3D405B; }
.project1 {
  background-color: #E07A5F; }
.project2 {
  background-color: #81B29A; }
.project3 {
  background-color: #7F8FA3; }
.hundred {
  background-color: rgba(0, 0, 0, .7) }

.btn-secondary {
  background-color: rgba(0,0,0,0);
}

.wide-modal {
  width: 1000px;
  right: 50%;
  padding: 20px;
}

.modal-title {
  font-size: 3em;
}


.close {
  color: #fff; 
  opacity: 1;
}

.close:hover {
  color: #fff; 
  opacity: .6;
}
    
.card-title {
  font-size: 1.8em;
}

.skills-block {
  background-color: #3D405B;
  color: #fff9f5;
  padding: 10% 20% 7%; }
  .skills-block h1 {
    line-height: .9em;
    padding: 15% 5%; }


.third-block {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
  padding: 20%; }

.connect {
  background-color: rgba(0, 0, 0, 0.7);
  padding-bottom: 5%;
  border-style: solid;
  border-color: #fff9f5; }

.site-footer a:link {
  color: #fff9f5;
  background-color: transparent;
  text-decoration: underline; }

.site-footer a:visited {
  color: #fff9f5;
  background-color: transparent;
  text-decoration: underline; }

.site-footer a:hover {
  color: #DEE2E6;
  background-color: transparent;
  text-decoration: underline; }

.site-footer a:active {
  color: #DEE2E6;
  background-color: transparent;
  text-decoration: underline; }

.fa-social {
  color: #fff9f5; }


.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}

a:link {
  color: #81B29A
}

a:visited {
  color: #81B29A
}

a:hover {
  color: #A6C9B8
}

.secret:visited {
  color: #3D405B
}
.secret:hover {
  color: #E07A5F;
  cursor: pointer;
}

.roo {
  background-color: #E07A5F;
}

#Challenge {
  background-color: #000000;
  color: #fff9f5;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 7em; } }

@media (max-width: 992px) {
  h1 {
    font-size: 5em; }
  p {
    font-size: .8em; } }
  
@media (max-width: 768px) {
  h1 {
    font-size: 4em; }
  p {
    font-size: .8em; } 
  .navbar-nav {
    margin-left: 0%;
  } }