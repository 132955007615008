.styled {
    background: #3D405B;
    color: #fff9f5;
    padding: 16px;
}

/* min-height: 100vh; // vh = view height */

.routeFilterPage {
    padding-top: 2%;
    padding-bottom: 5%;
    background: #fff9f5;
    
}
.ui.form input[type=text] {
    font-family: "Lora", serif; }

.appTitle {
    color: #3D405B;
}

.appStyle {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    
}



a.routeName {
    color: #FBBD06;
    font-size: 20px;
    font-family: "Lora", serif;
    font-weight: bold;
}

.routeLocation {
    color: lightgray;
    font-style: italic;
}

.routeName:hover {
    color: #E4A009;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: #26293D;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #E4A009 !important;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
    background-color: #E4A009 !important;
}

.ui.toggle.checkbox input:checked~.box, 
.ui.toggle.checkbox input:checked~label {
    color: white !important;
}

.ui.toggle.checkbox input:focus:checked~.box, 
.ui.toggle.checkbox input:focus:checked~label {
    color: white !important;
}

.sort {
    padding-left: .5em !important;
    padding-right: 1em !important;
}

.sortGroup {
    justify-content: center;
}